// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Hero from "../components/succes-clients/hero"
import Clients from "../components/succes-clients/clients"

const SuccesClients = ({ data, pageContext }) => {
  const { allContentfulFooter } = data
  const langKey = pageContext.langKey
  return (
    <>
      <Helmet>
        <title> Case Studies and happy clients on Amazon - Seelk </title>
        <meta
          name="description"
          content="Our customers are our best partners and advocates, discover their stories and how they won battles on Amazon."
        />
        <meta name="format-detection" content="telephone=no" />
        {langKey === "en" && <link rel="alternate" hreflang="en-us" />}
        {langKey === "fr" && <link rel="alternate" hreflang="fr" />}
      </Helmet>
      <Layout langKey={langKey} allContentfulFooter={allContentfulFooter}>
        <Hero langKey={langKey} />
        <Clients langKey={langKey} />
      </Layout>
    </>
  )
}

export default SuccesClients

export const data = graphql`
  query SuccessStoriesFrQuery($langKey: String!) {
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
