// eslint-disable-next-line no-unused-vars
import React from "react"

/** @jsx jsx */
import { jsx } from "theme-ui"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"
import Container from "../layout/container"
import Content from "../../content/succes-clients/hero.json"
import Span from "../common/span"
import BackgroundImage from "gatsby-background-image"

const Hero = ({ langKey }) => {
  const { title1, title2, text } = Content[langKey]

  const data = useStaticQuery(graphql`
    query SuccesClientHeroQuery {
      Arrow: file(relativePath: { eq: "arrow.png" }) {
        childImageSharp {
          fixed(width: 27, height: 66) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      Star: file(relativePath: { eq: "succesClientStar.png" }) {
        childImageSharp {
          fixed(width: 31, height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktop: file(relativePath: { eq: "SuccesClientHeroBg.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "successClientMobileHeroBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 376) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const sources = [
    data.mobile.childImageSharp.fluid,
    {
      ...data.desktop.childImageSharp.fluid,
      media: `(min-width: 750px)`,
    },
  ]

  return (
    <BackgroundImage fluid={sources}>
      <Container>
        <div
          sx={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            paddingTop: `14rem`,
            paddingBottom: [`5rem`, `5rem`, `9rem`, `9rem`],
          }}
        >
          <Img alt="" fixed={data.Star.childImageSharp.fixed} />
          <h1
            sx={{
              fontSize: [`1.6rem`, `1.6rem`, `3rem`, `3rem`],
              color: colors.code.secondary,
              textAlign: `center`,
              fontWeight: fontWeights.black,
              paddingTop: `2rem`,
            }}
          >
            {title1}&nbsp;
            <Span text={title2} />
          </h1>
          <p
            sx={{
              fontSize: `1.13rem`,
              color: colors.code.secondary,
              textAlign: `center`,
              width: [`100%`, `100%`, `30%`, `30%`],
            }}
          >
            {text}
          </p>
          <Img alt="" fixed={data.Arrow.childImageSharp.fixed} />
        </div>
      </Container>
    </BackgroundImage>
  )
}

export default Hero
