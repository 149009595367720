// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

const Span = ({ text }) => {
  return (
    <span
      sx={{
        background: `linear-gradient(180deg, transparent 50%, rgba(255, 164, 27, 0.7) 50%)`,
      }}
    >
      {text}
    </span>
  )
}

export default Span
