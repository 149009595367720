// eslint-disable-next-line no-unused-vars
import React from "react"
import { Link } from "gatsby"

/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"

import Button from "../button"

const ClientLeft = ({
  langKey,
  enLink,
  frLink,
  title,
  text,
  button,
  logo,
  logoMobile,
  pic,
  background,
  span,
}) => {
  return (
    <div sx={{ display: `flex` }}>
      <div
        sx={{
          width: [`100%`, `100%`, `50%`, `50%`],
          backgroundColor: background,
          alignItems: [`center`, `center`, `flex-start`, `flex-start`],
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          paddingLeft: [`0%`, `0%`, `10%`, `10%`],
          py: [`2rem`, `2rem`, `0rem`, `0rem`],
        }}
      >
        <div sx={{ display: [`flex`, `flex`, `none`, `none`] }}>
          <Img alt="" fixed={logoMobile} />
        </div>
        <div sx={{ display: [`none`, `none`, `flex`, `flex`] }}>
          <Img alt="" fixed={logo} />
        </div>
        <p
          sx={{
            color: colors.white,
            width: `90%`,
            textTransform: `uppercase`,
            fontSize: `1.06rem`,
            letterSpacing: `1.36px`,
            fontWeight: fontWeights.bold,
            marginTop: `2.5rem`,
            textAlign: [`center`, `center`, `left`, `left`],
          }}
        >
          <span
            sx={{
              color: colors.code.primary,
              fontSize: [`1.625rem`, `1.625rem`, `2.25rem`, `2.25rem`],
              fontWeight: fontWeights.black,
            }}
          >
            {span}
          </span>
          {title}
        </p>

        <p
          sx={{
            color: colors.white,
            width: `90%`,
            fontSize: `1rem`,
            textAlign: [`center`, `center`, `left`, `left`],
          }}
        >
          {text}
        </p>
        <Link
          href={langKey === "en" ? `${enLink}` : `${frLink}`}
          target="_blank"
          rel="noreferrer"
          sx={{ minWidth: "230px", width: "fit-content" }}
        >
          <Button>{button}</Button>
        </Link>
      </div>

      <div
        sx={{
          width: `50%`,
          overflow: `hidden`,
          display: [`none`, `none`, `block`, `block`],
          marginBottom: "-7px",
        }}
      >
        <Img alt="" fixed={pic} />
      </div>
    </div>
  )
}

export default ClientLeft
