// eslint-disable-next-line no-unused-vars
import React from "react"
import { Link } from "gatsby"

/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"

import Button from "../button"

const ClientRight = ({
  title,
  text,
  button,
  logo,
  pic,
  background,
  langKey,
  enLink,
  frLink,
}) => {
  return (
    <div sx={{ display: `flex` }}>
      <div
        sx={{
          width: `50%`,
          overflow: `hidden`,
          display: [`none`, `none`, `block`, `block`],
          marginBottom: "-8px",
        }}
      >
        <Img alt="" fixed={pic} />
      </div>
      <div
        sx={{
          width: [`100%`, `100%`, `50%`, `50%`],
          alignItems: [`center`, `center`, `flex-start`, `flex-start`],
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          paddingLeft: [`0%`, `0%`, `10%`, `10%`],
          py: [`2rem`, `2rem`, `0rem`, `0rem`],
          backgroundColor: background,
          textAlign: [`center`, `center`, `left`, `left`],
        }}
      >
        <Img alt="" fixed={logo} />
        <p
          sx={{
            color: colors.code.secondary,
            width: `90%`,
            textTransform: `uppercase`,
            fontSize: `1.06rem`,
            letterSpacing: `1.36px`,
            fontWeight: fontWeights.bold,
            marginTop: `2.5rem`,
            textAlign: [`center`, `center`, `left`, `left`],
          }}
        >
          {title}
        </p>
        <p
          sx={{
            color: colors.code.secondary,
            width: `90%`,
            fontSize: `1rem`,
          }}
        >
          {text}
        </p>

        <Link
          href={langKey === "en" ? `${enLink}` : `${frLink}`}
          target="_blank"
          rel="noreferrer"
          sx={{ minWidth: "230px" }}
        >
          <Button>{button}</Button>
        </Link>
      </div>
    </div>
  )
}

export default ClientRight
