// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Button as _Button } from "theme-ui"
import { colors } from "../utils/theme/colors"
import { fontWeights } from "../utils/theme/font-weights"

const Button = ({ children, overrideCSS }) => (
  <_Button
    sx={{
      //minWidth: "230px",
      width: "fit-content",
      height: `3.3rem`,
      //px: `7%`,
      color: colors.white,
      backgroundColor: colors.code.primary,
      borderRadius: `5px`,
      fontSize: `0.99rem`,
      fontWeight: fontWeights.semiBold,
      letterSpacing: `1.6px`,
      outline: `none`,
      ":hover": {
        background: `#F58D00 0% 0% no-repeat`,
        boxShadow: `0px 1px 20px #1F1F1F33`,
        borderRadius: `6px`,
        opacity: 1,
        cursor: `pointer`,
      },
      ...overrideCSS,
    }}
  >
    {children}
  </_Button>
)

export default Button
