// eslint-disable-next-line no-unused-vars
import React from "react"

/** @jsx jsx */
import { jsx } from "theme-ui"

import { useStaticQuery, graphql } from "gatsby"
import { colors } from "../../utils/theme/colors"
import Content from "../../content/succes-clients/clients.json"
import ClientLeft from "./client-left"
import ClientRight from "./client-right"

const Clients = ({ langKey }) => {
  const {
    button,
    span,
    PMtitle,
    PMcontent,
    SikaTitle,
    SikaContent,
    BicTitle,
    BicContent,
  } = Content[langKey]
  const data = useStaticQuery(graphql`
    query SuccesClientLeftQuery {
      PlaymobilPic: file(relativePath: { eq: "playmobilImg.png" }) {
        childImageSharp {
          fixed(width: 960, height: 900) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      PlaymobilLogo: file(relativePath: { eq: "playmobilLogo.png" }) {
        childImageSharp {
          fixed(width: 280, height: 111) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      PlaymobilLogoMobile: file(relativePath: { eq: "playmobilLogo.png" }) {
        childImageSharp {
          fixed(width: 188, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      SikaPic: file(relativePath: { eq: "sikaImg.png" }) {
        childImageSharp {
          fixed(width: 960, height: 900) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      SikaLogo: file(relativePath: { eq: "SikaLogo.png" }) {
        childImageSharp {
          fixed(width: 164, height: 143) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      BicPic: file(relativePath: { eq: "bicImg.png" }) {
        childImageSharp {
          fixed(width: 960, height: 900) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      BicLogo: file(relativePath: { eq: "bicLogo.png" }) {
        childImageSharp {
          fixed(width: 306, height: 97) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      BicLogoMobile: file(relativePath: { eq: "bicLogo.png" }) {
        childImageSharp {
          fixed(width: 245, height: 77) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div>
      <ClientLeft
        langKey={langKey}
        span={span}
        title={PMtitle}
        text={PMcontent}
        button={button}
        logo={data.PlaymobilLogo.childImageSharp.fixed}
        logoMobile={data.PlaymobilLogoMobile.childImageSharp.fixed}
        pic={data.PlaymobilPic.childImageSharp.fixed}
        background={colors.code.secondary}
        frLink={`/fr/blog/playmobil-sur-amazon-cas-client/`}
        enLink={`/blog/success-story-playmobil`}
      />
      <ClientRight
        langKey={langKey}
        title={SikaTitle}
        text={SikaContent}
        button={button}
        logo={data.SikaLogo.childImageSharp.fixed}
        pic={data.SikaPic.childImageSharp.fixed}
        background={colors.white}
        enLink={`/blog/sika-optimize-and-capitalized-on-its-brand-image-on-amazon`}
        frLink={`/fr/blog/sika-success-story-amazon`}
      />

      <ClientLeft
        langKey={langKey}
        title={BicTitle}
        text={BicContent}
        button={button}
        logo={data.BicLogo.childImageSharp.fixed}
        logoMobile={data.BicLogoMobile.childImageSharp.fixed}
        pic={data.BicPic.childImageSharp.fixed}
        background={colors.code.secondary}
        enLink={`/blog/retail-intelligence-at-the-service-of-media-acquisition`}
        frLink={`/fr/blog/lintelligence-retail-au-service-de-lacquisition-media/`}
      />
    </div>
  )
}

export default Clients
